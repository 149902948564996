@use "../global" as g;

header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  background: transparent;
  transition: background-color .3s;

  &.onlower {
    background-color: rgba(#000, 0.8);
  }

  .inner {
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5rem;

    @include g.mq-down(md) {
      height: 12rem;
      padding-left: 3.4rem;
    }

    ;

    .logo {
      width: 28.2rem;

      @include g.mq-down(md) {
        position: relative;
        z-index: 200;
        width: 43.2rem;
      }

    }

    .menu-btn {
      @include g.mq-up(md) {
        display: none;
      }

      width: 6rem+7.2rem;
      height: 3rem;
      padding: 0 3.6rem;
      z-index: 10000;

      .menu-trigger,
      .menu-trigger span {
        display: inline-block;
        box-sizing: border-box;
        transition: all .2s;
      }

      .menu-trigger {
        width: 100%;
        height: 2.5rem;
        position: relative;
      }

      .menu-trigger span {
        background-color: #fff;
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
      }

      //.menu-trigger.active span {
      //    background-color: #FFFFFF;
      //}
      .menu-trigger span:nth-of-type(1) {
        top: 0;
        transform: translateY(0) rotate(0);
      }

      .menu-trigger span:nth-of-type(2) {
        top: 9px;
        display: none;
      }

      .menu-trigger span:nth-of-type(3) {
        bottom: 0;
        transform: translateY(0) rotate(0);
      }

      /* Animation*/
      .menu-trigger.active span:nth-of-type(1) {
        top: 50%;
        transform: rotate(45deg);
      }

      .menu-trigger.active span:nth-of-type(2) {
        transform: scaleX(0);
      }

      .menu-trigger.active span:nth-of-type(3) {
        top: 50%;
        transform: rotate(-45deg);
      }
    }

    .menu {
      color: #fff;
      display: flex;
      align-items: center;

      @include g.mq-up(md) {
        visibility: visible !important;
        opacity: 1 !important;
      }

      @include g.mq-down(md) {
        opacity: 0;
        visibility: hidden;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 100vh;
        padding-top: 15rem;
        background: #000;

      }

      ul {
        height: 8rem;
        display: flex;
        align-items: center;

        @include g.mq-down(md) {
          height: auto;
          display: block;
        }

        li {
          margin-left: 2.5rem;
          font-size: 2rem;
          font-weight: bold;
          width: 100%;
          height: 8rem;
          position: relative;

          @include g.mq-down(md) {
            height: auto;
            margin-left: 0;
            padding: 0 7rem;
            font-size: 4.8rem;
            margin-bottom: 2rem;
          }

          @include g.mq-up(md) {
            &:hover {
              &::after {
                content: "";
                width: 100%;
                height: 0.4rem;
                background: #fff;
                position: absolute;
                bottom: 0;
                left: 0;
              }
            }
          }

          a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            position: relative;
            line-height: 1;

            @include g.mq-down(md) {
              line-height: 1.7;
            }
          }

          .child-nav {
            display: none;
            position: absolute;
            top: 100%;
            left: -4rem;
            width: 68rem;

            @include g.mq-down(md) {
              display: block !important;
              position: static;
              width: 100%;
              margin-bottom: 3rem;
            }

            //height: 29.1rem;
            @include g.mq-up(md) {
              &.--1 {
                .box {
                  padding: 0;

                  a {
                    width: 33%;
                    padding: 3rem 0;
                    text-align: center;
                    line-height: 1;

                    &::after {
                      content: " ＞";
                      display: inline;
                    }

                    &:hover {
                      background: #F0F0F0;
                    }
                  }
                }
              }
            }

            @include g.mq-down(md) {
              &.--1 {
                .box {
                  a {
                    &::before {
                      content: "ー ";
                      display: inline;
                    }
                  }
                }
              }

              &.--2 {
                .box {
                  a {
                    p {
                      &::before {
                        content: "ー ";
                        display: inline;
                      }
                    }
                  }
                }
              }
            }


            .box {
              margin-top: 2.4rem;
              display: flex;
              justify-content: space-between;
              width: 100%;
              height: 100%;
              padding: 4rem;
              background: #fff;

              @include g.mq-down(md) {
                font-size: 3rem;
                display: block;
                background: transparent;
                padding: 0;
                margin-top: 0;
              }

              a {
                display: block;
                width: 28rem;
                color: #000;

                @include g.mq-down(md) {
                  width: auto;
                  color: #666;
                }

                figure {
                  margin-bottom: 1rem;
                  height: 17.7rem;
                  @include g.imgFit();

                  @include g.mq-down(md) {
                    display: none;
                  }
                }

                // &:hover {
                //   &:after {
                //     content: none;
                //   }
                // }
              }
            }
          }
        }
      }

      .privacy {
        display: none;

        @include g.mq-down(md) {
          font-size: 3.5rem;
          margin: 0 7rem 7rem;
          display: block;
          font-weight: 700;
          padding-bottom: 9.5rem;
          border-bottom: 0.1rem solid #666;

        }
      }

      .tel {
        width: 25rem;
        height: 8rem;
        background: #000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 2rem;
        margin-left: 2.5rem;

        @include g.mq-down(md) {
          padding: 0 7rem;
          width: 100%;
        }

        .read {
          display: block;
          font-size: 1.2rem;
          margin-bottom: 0.5rem;

          @include g.mq-down(md) {
            font-size: 3rem;
          }
        }

        .number {
          display: flex;
          align-items: center;
          font-size: 2.7rem;
          font-weight: 700;
          line-height: 1;

          @include g.mq-down(md) {
            font-size: 7.3rem;
          }

          span {
            @include g.mq-down(md) {
              display: contents;
            }
          }

          svg {
            width: 1.7rem;
            height: 2.6rem;
            margin-right: 0.5rem;
            fill: #fff;

            @include g.mq-down(md) {
              width: 5rem;
              height: 5.3rem;
              margin-right: 1rem;
            }
          }

        }
      }
    }
  }
}
