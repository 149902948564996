@use "../global" as g;

.p-company {
  .content-sec {
    position: relative;
    background-color: #000;
    height: 60rem;

    @include g.mq-down(md) {
      height: 120rem;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.6);
      mix-blend-mode: multiply;
    }

    .inner {
      margin-left: calc(50vw - 108rem / 2);
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      z-index: 1;
      color: #fff;

      @include g.mq-down(md) {
        margin: 0 4rem;
      }

      .container {
        display: flex;
        justify-content: space-between;

        @include g.mq-down(md) {
          display: block;
        }

        .midashi {
          width: 36rem;

          @include g.mq-down(md) {
            width: 100%;
            margin-bottom: 8rem;
          }

          h2 {
            .en {
              font-size: 6.6rem;
              letter-spacing: 0.3rem;
              line-height: 1.4;
              font-weight: 700;

              @include g.mq-down(md) {
                font-size: 8.2rem;
              }
            }

            .ja {
              font-size: 2rem;
              font-weight: 700;

              @include g.mq-down(md) {
                font-size: 2.6rem;
              }
            }
          }

        }

        .read {
          width: calc(100% - 36rem);

          @include g.mq-down(md) {
            width: 100%;
          }
        }

        .catch {
          font-size: 3.4rem;
          font-weight: 700;
          letter-spacing: 0.3rem;
          margin-bottom: 3rem;

          @include g.mq-down(md) {
            font-size: 4.4rem;
            text-align: justify;
          }
        }

        .outline {
          font-size: 2rem;
          letter-spacing: 0.2rem;
          line-height: 2;

          @include g.mq-down(md) {
            font-size: 2.8rem;
            text-align: justify;
          }

          ul {
            li {
              display: flex;

              &+li {
                margin-top: 0.5rem;
              }

              span {
                width: calc(100% - 5rem);

                @include g.mq-down(md) {
                  width: calc(100% - 7rem);
                }

                &:nth-of-type(1) {
                  width: 5rem;

                  @include g.mq-down(md) {
                    width: 7rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    figure {
      height: 60rem;
      width: 100%;
      @include g.imgFit();

      @include g.mq-down(md) {
        height: 120rem;
      }
    }

    &.vision-sec {

      figure {
        @include g.mq-down(md) {
          @include g.imgFit(30%, 50%);
        }
      }

    }
  }


  .message-sec {
    padding: 14rem 0;

    .inner {
      .midashi {
        margin-bottom: 8rem;

        .en {
          font-size: 8rem;
          letter-spacing: 0.3rem;
          font-weight: 700;
          line-height: 1.3;
        }

        .ja {
          font-size: 2.2rem;
          color: #808080;
          font-weight: 700;

          @include g.mq-down(md) {
            font-size: 2.8rem;
          }
        }
      }

      .container {
        display: flex;
        justify-content: space-between;

        @include g.mq-down(md) {
          display: block;
        }

        .photo {
          width: 40rem;

          @include g.mq-down(md) {
            width: calc(100% - 8rem);
            margin: 0 auto 6rem;
          }
        }

        .text {
          width: 60rem;
          font-size: 2rem;
          line-height: 2;

          @include g.mq-down(md) {
            width: 100%;
            font-size: 2.8rem;
            line-height: 1.8;
          }

          p {
            text-align: justify;
          }

          .sign {
            margin-top: 4rem;
            text-align: right;
          }

        }
      }
    }
  }

  .profile-sec {
    background: #000;
    padding: 14rem 0;

    .inner {
      color: #fff;

      .midashi {
        margin-bottom: 8rem;

        .en {
          display: inline-block;
          font-size: 5rem;
          font-weight: 700;
          letter-spacing: 0.3rem;
          margin-right: 2rem;

          @include g.mq-down(md) {
            display: block;
            font-size: 6rem;
            margin-bottom: 1rem;
          }
        }

        .ja {
          display: inline-block;
          font-size: 2rem;
          font-weight: 700;

          @include g.mq-down(md) {
            display: block;
            font-size: 2.5rem;
          }

        }
      }

      .table {
        dl {
          display: flex;
          align-items: flex-start;
          padding: 5rem 0;
          border-bottom: 0.1rem solid #808080;
          font-size: 2rem;
          line-height: 2;

          @include g.mq-down(md) {
            font-size: 2.8rem;
            display: block;
            padding: 6rem 0;
          }

          &:nth-of-type(1) {
            border-top: 0.1rem solid #808080;
          }

          dt {
            width: 20rem;

            @include g.mq-down(md) {
              width: 100%;
              font-weight: 700;
              margin-bottom: 1rem;
            }
          }

          dd {
            width: calc(100% - 20rem);

            @include g.mq-down(md) {
              width: 100%;
            }
          }
        }
      }

      .map {
        width: 100%;
        height: 47rem;

        iframe {
          width: 100%;
          height: 100%;
          filter: grayscale(100%);
        }
      }

      .map-link {
        width: fit-content;
        display: block;
        margin-top: 2rem;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
