//ブレイクポイント
// 変数の定義
// min-width
$breakpoint-up: (
  "sm": "screen and (min-width: 401px)",
  "md": "screen and (min-width: 769px)",
  "mdx": "screen and (min-width: 901px)",
  "lg": "screen and (min-width: 1081px)",
  "xl": "screen and (min-width: 1101px)",
  "xxl": "screen and (min-width: 1149px)",
  "xxxl": "screen and (min-width: 1281px)",
  "xxxxl": "screen and (min-width: 1367px)",
  "xxxxxl": "screen and (min-width: 1681px)",
  ) !default;
// max-width
$breakpoint-down: (
  "sm": "screen and (max-width: 400px)",
  "md": "screen and (max-width: 768px)",
  "mdx": "screen and (max-width: 900px)",
  "lg": "screen and (max-width: 1080px)",
  "xl": "screen and (max-width: 1100px)",
  "xxl": "screen and (max-width: 1148px)",
  "xxxl": "screen and (max-width: 1280px)",
  "xxxxl": "screen and (max-width: 1366px)",
  "xxxxxl": "screen and (max-width: 1680px)",
  ) !default;
//
$breakpoint-between: (
  "bw769-1366": "(min-width: 769px) and (max-width: 1366px) ",
  ) !default;
//



// @mixinの定義
@mixin mq-up($breakpoint: md) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin mq-down($breakpoint: md) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

@mixin mq-between($breakpoint: bw769-1366) {
  @media #{map-get($breakpoint-between, $breakpoint)} {
    @content;
  }
}
