@use "../global" as g;

.inquiry-sec {
  position: relative;
  height: 44.7rem;
  overflow: hidden;

  @include g.mq-down(md) {
    height: 86.9rem;
  }

  a {
    width: 100%;
    height: 100%;
    display: block;
    @include g.image-link_zoom();


    .inner {
      position: absolute;
      top: 50%;
      left: calc(50% - 108rem / 2);
      transform: translateY(-50%);
      z-index: 2;

      @include g.mq-down(md) {
        left: 0;
        padding: 3.2rem;
        width: 100%;
      }

      .midashi {
        font-size: 8rem;
        font-weight: 700;
        letter-spacing: 0.6rem;
        color: #fff;

        @include g.mq-down(md) {
          font-size: 9.4rem;
          margin-bottom: 4rem;
        }
      }

      .summary {
        color: #fff;
        font-size: 2rem;

        @include g.mq-down(md) {
          font-size: 2.8rem;
        }
      }

      .link-btn {
        position: absolute;
        right: 0;
        bottom: 0;
        width: fit-content;

        @include g.mq-down(md) {
          position: static;
          margin-top: 8rem;
          margin-left: calc(100% - 14.259*2rem);
        }
      }
    }


    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: rgba(#000, 0.7);
      mix-blend-mode: multiply;
    }

    .parallax {
      width: 100%;
      height: 44.7rem;
      @include g.imgFit();

      @include g.mq-down(md) {
        height: 86.9rem;
      }
    }
  }
}
