@use "../global" as g;

.link-btn {
  color: #fff;
  display: flex;
  align-items: center;

  .txt {
    margin-right: 1rem;
    font-size: 1.8rem;
    position: relative;
    color: #b2b2b2;

    @include g.mq-down(md) {
      font-size: 3rem;
      margin-right: 2rem;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -1rem;
      width: 100%;
      height: 0.1rem;
      background: #fff;
    }
  }

  .icon {
    width: 5.7rem;
    height: 5.7rem;
    border-radius: 50%;
    background: #000;
    border: 0.1rem solid #808080;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .2s, fill .2s;

    @include g.mq-down(md) {
      width: 10rem;
      height: 10rem;
    }

    svg {
      display: block;
      fill: #808080;
      width: 3.7rem;
      height: 1.5rem;

      @include g.mq-down(md) {
        width: 6.1rem;
        height: 2.5rem;
      }
    }
  }

  &:hover {
    // .icon {
    //   background: transparent;
    //   border-color: #fff;
    // }
  }
}

a {
  &:hover {
    .link-btn {

      .icon {
        background: #808080;

        //border-color: #fff;
        svg {
          fill: #000;
        }
      }

    }
  }
}

.link-btn2 {
  color: #fff;
  display: flex;
  align-items: center;

  .txt {
    margin-right: 1rem;
    font-size: 1.8rem;
    position: relative;
    color: #808080;

    @include g.mq-down(md) {
      font-size: 3rem;
      margin-right: 2rem;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -1rem;
      width: 100%;
      height: 0.1rem;
      background: #000;
    }
  }

  .icon {
    width: 5.7rem;
    height: 5.7rem;
    border-radius: 50%;
    background: #808080;
    border: 0.1rem solid #808080;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .2s, fill .2s;

    @include g.mq-down(md) {
      width: 10rem;
      height: 10rem;
    }

    svg {
      display: block;
      fill: #fff;
      width: 3.7rem;
      height: 1.5rem;

      @include g.mq-down(md) {
        width: 6.1rem;
        height: 2.5rem;
      }

      path {
        fill: #fff;
      }
    }
  }

  &:hover {
    .icon {
      background: #000;
      border-color: #000;
    }
  }
}

a {
  &:hover {
    .link-btn2 {

      .icon {
        background: #fff;

        svg {
          fill: #808080;
        }
      }

    }
  }
}

.link-btn3 {
  color: #fff;
  display: flex;
  align-items: center;

  .txt {
    margin-right: 1rem;
    font-size: 1.8rem;
    position: relative;
    color: #b2b2b2;

    @include g.mq-down(md) {
      font-size: 3rem;
      margin-right: 2rem;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -1rem;
      width: 100%;
      height: 0.1rem;
      background: #fff;
    }
  }

  .icon {
    width: 5.7rem;
    height: 5.7rem;
    border-radius: 50%;
    background: transparent;
    border: 0.1rem solid #808080;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .2s, fill .2s;

    @include g.mq-down(md) {
      width: 10rem;
      height: 10rem;
    }

    svg {
      display: block;
      fill: #808080;
      width: 3.7rem;
      height: 1.5rem;

      @include g.mq-down(md) {
        width: 6.1rem;
        height: 2.5rem;
      }
    }
  }

  &:hover {
    // .icon {
    //   background: transparent;
    //   border-color: #fff;
    // }
  }
}

a {
  &:hover {
    .link-btn3 {

      .icon {
        background: #808080;

        //border-color: #fff;
        svg {
          fill: #000;
        }
      }

    }
  }
}
