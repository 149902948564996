@use "../global" as g;

.pagehead-sec {
  height: 40.7rem;
  background-color: #000;
  position: relative;

  @include g.mq-down(md) {
    height: 61rem;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.8);
    mix-blend-mode: multiply;
  }

  .inner {
    width: 108rem;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    @include g.mq-down(md) {
      width: 100%;
      padding: 0 4rem;
    }

    .title {
      color: #fff;
      align-items: center;

      .ja {
        font-size: 2rem;

        @include g.mq-down(md) {
          font-size: 2.5rem;
          margin-bottom: 2rem;
        }
      }

      .en {
        font-size: 5.6rem;
        font-weight: 700;
        letter-spacing: 0.3rem;

        @include g.mq-down(md) {
          font-size: 6.2rem;
          line-height: 1.2;
        }
      }
    }

    .topicpath {
      position: absolute;
      bottom: 3rem;
      font-size: 1.4rem;
      color: #fff;

      @include g.mq-down(md) {
        font-size: 2.2rem;
      }
    }
  }

  figure {
    height: 40.7rem;
    @include g.imgFit();

    @include g.mq-down(md) {
      height: 61rem;
    }
  }

}
