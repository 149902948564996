@use "../global" as g;

.summarycontent-sec {
  padding: 14rem 0 11rem;

  @include g.mq-down(md) {
    padding: 18rem 0;
  }

  .inner {
    padding-left: calc(50vw - 108rem / 2);

    @include g.mq-down(md) {
      padding-left: 4rem;
    }

    .midashi {
      font-size: 4rem;
      font-weight: 700;
      margin-bottom: 2rem;

      @include g.mq-down(md) {
        font-size: 5rem;
      }

      &::before {
        content: "";
        display: inline-block;
        width: 2rem;
        height: 2rem;
        background-color: #e60013;
        margin-right: 1rem;
        transform: translateY(-0.5rem);

        @include g.mq-down(md) {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }

    .catch {
      font-size: 3.4rem;
      margin-bottom: 3rem;
      font-weight: 700;

      @include g.mq-down(md) {
        font-size: 4.4rem;
        text-align: justify;
      }
    }

    .desc {
      font-size: 2rem;
      line-height: 2;

      @include g.mq-down(md) {
        font-size: 2.8rem;
        text-align: justify;

      }
    }

    .container {
      display: flex;

      @include g.mq-down(md) {
        display: block;
      }

      .text {
        width: 68rem;
        padding-right: 8rem;

        @include g.mq-down(md) {
          width: 100%;
          padding-right: 4rem;
          margin-bottom: 8rem;
        }

      }

      .photo {
        width: 54.4rem;

        @include g.mq-down(md) {
          width: 100%;
        }

        figure {
          width: 100%;
          height: 43.7rem;
          @include g.imgFit();

          @include g.mq-down(md) {
            height: 45.2rem;
          }
        }
      }
    }
  }

  &.support-sec {
    .inner {
      .midashi {
        font-size: 3rem;

        &::before {
          transform: translateY(-0.1rem);
        }

        @include g.mq-down(md) {
          font-size: 4.6rem;

          &::before {
            transform: translateY(-0.5rem);
          }

        }
      }
    }
  }
}
