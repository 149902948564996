@use "../global" as g;

footer {
  background: #000;
  padding: 14rem 0;

  .inner {
    .logo {
      width: 34.8rem;
      margin-bottom: 5rem;

      @include g.mq-down(md) {
        width: 54.8rem;
      }
    }

    .container {
      display: flex;
      margin-bottom: 4rem;

      .place {
        width: 44rem;
        color: #808080;

        @include g.mq-down(md) {
          width: 100%;
        }

        p {
          margin-bottom: 1rem;
        }
      }

      .menu {
        width: calc(100% - 44rem);
        color: #fff;
        display: flex;
        justify-content: space-between;

        @include g.mq-down(md) {
          display: none;
        }

        a {
          display: block;
          font-size: 2.2rem;
          font-weight: bold;
          margin-bottom: 1.8rem;

          &.privacy {
            font-size: 1.6rem;
            margin-top: 3rem;
          }
        }

        .child-nav {
          a {
            font-size: 1.4rem;
            font-weight: 400;
            margin: 0.5rem;
            color: #808080;

            &::before {
              content: "ー ";
            }

            &:hover {
              color: #fff;
            }
          }
        }
      }
    }

    .copyright {
      color: #808080;

    }
  }
}
