@use "../global" as g;
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

//slick default style
.slick-dots {
  //bottom: -30px ;
}

.slick-dots li {
  margin: 0;

  button:before {
    font-family: sans-serif;
    content: "●";
    color: #b3b3b3;
  }

  &.slick-active button:before {
    color: g.$brown ;
  }
}

.slick-loading .slick-list {
  background: none;
}

.slick-prev:before,
.slick-next:before {
  content: "";
  display: block;
  background-image: url(../images/common/icon_arrow_wh.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 3.7rem;
  height: 1.5rem;
  display: block;
  opacity: 1;

  @include g.mq-down(md) {
    width: 6.1rem;
    height: 2.4rem;
  }
}

.slick-prev:before {
  transform: rotate(-180deg);
}

.slick-next {
  left: 7.5rem;
  right: auto;

  @include g.mq-down(md) {
    left: auto;
    right: 4rem;
  }

}

.slick-prev {
  left: 0;

  @include g.mq-down(md) {
    left: calc(100% - 10rem - 4rem - 12.5rem);
  }

}

.slick-next,
.slick-prev {
  width: 5.7rem;
  height: 5.7rem;
  background-color: #808080;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -5rem;

  @include g.mq-down(md) {
    width: 10rem;
    height: 10rem;
    top: -7rem;
  }

}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background: #000;
}
