@use "../global" as g;

.p-top {

  .bg-movie {
    //position:absolute;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    background: #000;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(#000000, 0.4);
      mix-blend-mode: multiply;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      transition: background-color 0.5s;
    }

    &.onview {
      &::after {
        background-color: rgba(#000000, 0.7);
      }
    }

    figure {
      width: 100%;
      height: 100%;
      @include g.imgFit(0, 0);

      opacity: 0;
      transform: scale(1.2);
      transition: opacity 0.8s, transform 1.4s cubic-bezier(0.03, 0.52, 0, 1);

      &.onload {
        opacity: 1;
        transform: scale(1);
      }
    }

    &.active {
      position: absolute;
      top: 100vh;
      left: 0;
    }
  }

  .mv-sec {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    h1 {
      color: #fff;
      padding-left: calc(50vw - 108rem / 2);
      position: relative;
      z-index: 1000;
      z-index: 10;
      opacity: 0;
      transform: translateX(-20rem);
      transition: transform 0.7s cubic-bezier(0.03, 0.52, 0, 1);

      @include g.mq-down(md) {
        padding: 0 0 0 4rem;
      }

      &.onload {
        opacity: 1;
        transform: translateX(0);
      }

      .en {
        font-size: 9rem;
        font-weight: 700;
        letter-spacing: 0.6rem;
        line-height: 1.3;

        @include g.mq-down(md) {
          font-size: 8rem;
        }

        p {
          @include g.mq-up(md) {
            br {
              &:nth-last-of-type(1) {
                display: none;
              }
            }
          }

          span {
            padding-right: 0.8rem;
          }

          .row {
            display: block;
            margin: 2.5rem 0;

            &:nth-of-type(2) {
              margin-left: 26rem;

              @include g.mq-down(md) {
                margin-left: 0;
              }
            }
          }
        }
      }

      .ja {
        margin-top: 4rem;
        font-size: 2.5rem;

        @include g.mq-down(md) {
          font-size: 2.8rem;
          margin-top: 6rem;
        }

        p {
          @include g.mq-down(md) {
            margin-bottom: 2rem;
          }
        }

        span {
          margin-right: 0.25rem;

          @include g.mq-down(md) {
            margin-right: 0.3rem;

          }

        }
      }



      .strip {
        span {
          display: inline-block;
          transition: transform .6s cubic-bezier(.65, .02, .23, 1);
          transform: translate(20%, 100%);
          position: relative;
          z-index: 1;
          letter-spacing: -0.03em;
          text-shadow: 3px 4px 0 rgba(0, 0, 0, .1);



          &:before {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateY(-40%);
            transition: transform .6s cubic-bezier(.65, .02, .23, 1);
          }

          &.row {
            overflow: hidden;
            line-height: 1;
            display: block;
            transform: none;

            &:before {
              display: none;
            }
          }
        }

        .animate {
          transform: translate(0, 0);

          &:before {
            transform: translateY(100%);
          }
        }
      }



    }
  }

  .about-sec {
    position: relative;
    //height: 120rem;
    overflow: hidden;
    padding-top: 10rem;

    @include g.mq-down(md) {
      overflow: auto;
      height: auto;
      padding-top: 0;

    }

    .inner {
      position: static;
      //position: absolute;
      top: 50%;
      //transform: translateY(-50%);
      width: 100%;
      padding-left: calc(50vw - 108rem / 2);
      margin: 0;
      z-index: 1;

      @include g.mq-down(md) {
        transform: none;
        position: static;
      }

      .container {
        display: flex;
        color: #fff;
        padding: 0 0 24rem;

        @include g.mq-down(md) {
          display: block;
          padding: 0 4rem 0;
        }

        .midashi {
          display: block;
          font-size: 3.3rem;
          font-weight: 700;
          margin-right: 5.5rem;

          @include g.mq-down(md) {
            margin-right: 0;
            font-size: 4.2rem;
            margin-bottom: 4rem;
          }
        }

        .introduction {
          @include g.mq-down(md) {
            margin-bottom: 10rem;
          }

          .catch {
            margin-top: -1rem;
            margin-bottom: 5rem;

            .ja {
              display: block;
              font-size: 4rem;
              font-weight: 500;
              letter-spacing: 0.6rem;
              margin-bottom: 3rem;

              @include g.mq-down(md) {
                font-size: 5.7rem;
              }

              ;
            }

            .en {
              font-size: 1.8rem;
              font-weight: 500;
              letter-spacing: 0.25rem;

              @include g.mq-down(md) {
                font-size: 2.6rem;
              }

            }

          }

          .read {
            font-size: 2rem;
            letter-spacing: 0.3rem;
            line-height: 2.6;

            @include g.mq-down(md) {
              text-align: justify;
              font-size: 2.8rem;
              line-height: 1.9;
            }
          }

          .read+a {
            display: block;
            margin-top: 4rem;
            text-align: right;
            width: fit-content;
            margin-left: calc(100% - 17rem);

            @include g.mq-down(md) {
              margin-left: calc(100% - 30rem);
            }
          }
        }
      }
    }
  }

  .service-sec {
    padding: 14rem 0;
    background: #f7f7f7;

    @include g.mq-down(md) {
      padding: 17rem 0;
    }

    .inner {
      .midashi {
        font-size: 8rem;
        letter-spacing: 0.3rem;
        font-weight: 700;
        margin-bottom: 4rem;

        @include g.mq-down(md) {
          font-size: 9.4rem;
          margin-bottom: 8rem;
        }
      }

      .list {
        display: flex;
        justify-content: space-between;

        @include g.mq-down(md) {
          display: block;
        }

        .card {
          width: 48rem;

          @include g.mq-down(md) {
            width: 100%;
            margin-bottom: 16rem;

            &:nth-last-of-type(1) {
              margin-bottom: 0;
            }
          }

          a {
            display: block;
            @include g.image-link_zoom();
          }

          figure {
            margin-bottom: 1.5rem;

            @include g.mq-down(md) {
              margin-bottom: 4rem;
            }
          }

          .caption {
            .komidashi {
              font-size: 2.6rem;
              font-weight: 700;
              letter-spacing: 0.3rem;
              margin-bottom: 1rem;

              @include g.mq-down(md) {
                font-size: 3.8rem;
              }
            }

            .desc {
              line-height: 2;
              margin-bottom: 2rem;
              text-align: justify;

              @include g.mq-down(md) {
                margin-bottom: 4rem;
              }
            }
          }
        }
      }
    }
  }

  .works-sec {
    background: #000;
    padding-bottom: 14rem;
    overflow: hidden;

    @include g.mq-down(md) {
      padding-bottom: 15rem;
    }

    .bg {
      width: 100%;
      height: 38.5rem;
      @include g.imgFit();
      position: relative;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: rgba(#000, 0.7);
        mix-blend-mode: multiply;

      }

    }

    .inner {
      position: relative;
      margin-top: -24.2rem;
      color: #fff;
      padding-left: calc(50vw - 108rem / 2);

      .midashi {
        font-size: 8rem;
        letter-spacing: 0.3rem;
        font-weight: 700;
        margin-bottom: 4rem;
        width: 108rem;
        text-align: right;

        @include g.mq-down(md) {
          font-size: 9.4rem;
          margin-bottom: 8rem;
          text-align: left;
          width: 100%;
          padding: 0 4rem;
        }
      }

      .slides {
        margin-bottom: 12rem;

        .slide {
          margin-right: 6rem;

          @include g.mq-down(md) {
            margin: 0 2.35rem;
          }

          .box {
            width: 32rem;

            @include g.mq-down(md) {
              width: 51.3rem;
            }

            a {
              @include g.image-link_zoom();
            }

            figure {
              margin-bottom: 1rem;

              @include g.mq-down(md) {
                margin-bottom: 3rem;
              }
            }

            .caption {
              .title {
                font-size: 2.2rem;
                margin-bottom: 1rem;
                font-weight: 500;

                @include g.mq-down(md) {
                  font-size: 3.2rem;
                  margin-bottom: 2rem;
                }
              }

              .desc {
                line-height: 1.8;
                text-align: justify;
              }

              .tag {
                margin-top: 2rem;

                span {
                  font-size: 1.4rem;
                  color: #b2b2b2;
                  display: inline-block;
                  border: 0.1rem solid #b2b2b2;
                  border-radius: 9999px;
                  padding: 0.1rem 1rem;
                  margin: 0 1rem 1rem 0;

                  @include g.mq-down(md) {
                    font-size: 2.8rem;
                    padding: 0.15rem 2.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    .slider {
      display: flex;
      animation: 80s linear infinite sliderAnimation_horizontal;
      width: 132.3*6rem;

      @include g.mq-down(md) {
        width: 171.8*6rem;
        animation: 100s linear infinite sliderAnimation_horizontal;
      }

      @include g.mq-down(md) {
        // width: g.spvw(10800);
        // animation: 120s linear infinite sliderAnimation_horizontal;
      }

      .slide {
        width: 171.8rem;
        height: 11.88rem;



        figure {
          width: 100%;
          height: 100%;
          @include g.imgFit();
        }
      }

      @keyframes sliderAnimation_horizontal {
        100% {
          transform: translateX(-50%);
        }
      }
    }
  }

  .interview-sec {
    background: #F7F7F7;
    padding: 14rem 0;

    @include g.mq-down(md) {
      padding: 15rem 0;
    }

    .inner {
      .midashi {
        font-size: 8rem;
        letter-spacing: 0.3rem;
        font-weight: 700;
        margin-bottom: 4rem;

        @include g.mq-down(md) {
          font-size: 9.4rem;
          margin-bottom: 8rem;
        }
      }

      .container {
        display: flex;
        justify-content: space-between;
        @include g.image-link_zoom();

        @include g.mq-down(md) {
          display: block;
        }

        .photo {
          width: 56rem;

          @include g.mq-down(md) {
            width: 100%;
            margin-bottom: 3rem;
          }
        }

        .text {
          width: 48rem;

          @include g.mq-down(md) {
            width: 100%;
          }

          .nakamidashi {
            font-size: 3.4rem;
            margin-bottom: 2rem;
            font-weight: 700;

            @include g.mq-down(md) {
              font-size: 4.4rem;
            }
          }

          .company {
            margin-bottom: 0.5rem;

            @include g.mq-down(md) {
              margin-bottom: 1rem;
            }
          }

          .komidashi {
            color: #808080;
            margin-bottom: 1.5rem;
          }

          .desc {
            line-height: 1.8;
            text-align: justify;
          }

          .link-btn2 {
            margin-top: 4rem;
          }
        }
      }
    }
  }

  .other-sec {
    margin-top: 20rem;
    background-color: #000;
    position: relative;
    padding-bottom: 14rem;

    @include g.mq-down(md) {
      padding-bottom: 15rem;
    }

    .inner {
      .container {
        display: flex;
        justify-content: space-between;
        padding: 0 8rem;
        color: #fff;

        @include g.mq-down(md) {
          display: block;
          padding: 0 4rem;
        }

        &::before {
          content: "";
          width: calc(50vw - 108rem / 2);
          height: 14rem;
          background: #F7F7F7;
          position: absolute;
          top: 0;
          left: 0;

          @include g.mq-down(md) {
            width: 4rem;
            height: 17rem;
          }
        }

        &::after {
          content: "";
          width: calc(50vw - 108rem / 2);
          height: 14rem;
          background: #F7F7F7;
          position: absolute;
          top: 0;
          right: 0;

          @include g.mq-down(md) {
            width: 4rem;
            height: 17rem;
          }
        }

        .box {
          width: 40rem;

          @include g.mq-down(md) {
            width: 100%;
            margin-bottom: 7rem;

            &:nth-last-of-type(1) {
              margin-bottom: 0;
            }
          }

          a {
            @include g.image-link_zoom();
          }

          .midashi {
            color: #fff;
            padding-top: 6rem;
            margin-bottom: 4rem;

            @include g.mq-down(md) {
              padding-top: 9rem;
            }

            .en {
              font-size: 8rem;
              letter-spacing: 0.3rem;
              font-weight: 700;
              line-height: 1.3;

              @include g.mq-down(md) {
                font-size: 9.4remm;
              }
            }

            .ja {
              font-size: 2rem;
              color: #b2b2b2;

              @include g.mq-down(md) {
                font-size: 2.8rem;
              }
            }
          }

          figure {
            margin-bottom: 1rem;

            @include g.mq-down(md) {
              margin-bottom: 3rem;
            }
          }

          .caption {
            .komidashi {
              font-size: 2.6rem;
              font-weight: 700;
              margin-bottom: 1.5rem;

              @include g.mq-down(md) {
                font-size: 4.4rem;
              }
            }

            .desc {
              line-height: 1.8;
              text-align: justify;
            }

            .link-btn {
              margin-top: 4rem;
            }
          }
        }
      }
    }
  }
}
