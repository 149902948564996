@use "../global" as g;

.p-privacypolicy {


  .provision-sec {
    padding: 14rem 0 5rem;

    @include g.mq-down(md) {
      padding-bottom: 10rem;
    }

    &+.provision-sec {
      padding-top: 0rem;
      padding-bottom: 14rem;
    }

    .inner {
      .midashi {
        font-size: 2.2rem;
        font-weight: 700;
        margin-bottom: 2rem;

        @include g.mq-down(md) {
          font-size: 3.2rem;
        }
      }

      p {
        line-height: 2;
        text-align: justify;

        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
