@use "../global" as g;

@include g.mq-up(md) {

  html,
  body {

    cursor: none;
    overflow-x: hidden;

    a {
      cursor: none;

      &:hover {
        cursor: none;
      }
    }

    label {
      cursor: none !important;
    }

    button {
      cursor: none !important;
    }
  }

  .cursor,
  .follower {
    position: absolute;
    top: 0;
    left: 0;
    //transform: translate(-50%, -50%);
    border-radius: 50%;
    pointer-events: none;
  }

  .cursor {
    z-index: 11001;
    width: 8px;
    height: 8px;
    background-color: #fff;
    mix-blend-mode: difference;
    transition: opacity linear 0.2s;
    margin: -4px 0 0 -4px;

    &.is-active {
      opacity: 0.2;
    }
  }

  .follower {
    z-index: 1000;
    width: 40px;
    height: 40px;
    background-color: rgba(#999, 0.1);
    //transition: transform linear 0.2s, width ease 0.1s, height ease 0.1s, background-color ease 0.2s;
    transition: width ease 0.1s, height ease 0.1s, background-color ease 0.2s;
    border: 1px solid #CCC;
    margin: -20px 0 0 -20px;



    &.is-active {
      width: 120px;
      height: 120px;
      background: transparent;
      border: 1px solid #333;
      //transition: transform linear 0.2s, width ease 0.1s, height ease 0.1s, background-color ease 0.2s;
      transition: width ease 0.1s, height ease 0.1s, background-color ease 0.2s;
      margin: -60px 0 0 -60px;

      &::before {
        content: "";
        background-color: rgba(#000, 0.8);
        border-radius: 50%;
        mix-blend-mode: multiply;
        width: 100%;
        height: 100%;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
      }

      &::after {
        content: "View more";
        width: 100%;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 14px;
      }
    }

    &.is-labelhover {
      width: 60px;
      height: 60px;
      background: transparent;
      border: 1px solid #333;
      margin: -30px 0 0 -30px;

      &::before {
        content: "";
        background-color: rgba(#000, 0.6);
        border-radius: 50%;
        mix-blend-mode: multiply;
        width: 100%;
        height: 100%;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.is-buttonhover {
      width: 120px;
      height: 120px;
      background: transparent;
      border: 1px solid #333;
      margin: -60px 0 0 -60px;

      &::before {
        content: "";
        background-color: rgba(#000, 0.6);
        border-radius: 50%;
        mix-blend-mode: multiply;
        width: 100%;
        height: 100%;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
