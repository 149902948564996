@use "../global" as g;

.p-lower {
  // header {
  //   background-color: rgba(0, 0, 0, 0.8);
  // }
}

.inner {
  &.--1080 {
    width: 108rem;
    margin: 0 auto;

    @include g.mq-down(md) {
      width: 67.8rem;
    }
  }

}
