@use "../breakpoint" as b;
$text-color: #333;
$text-color2: #98907E;
$beige : #DEDBD4;
$brown: #707070;
$thin-brown: #C9C1B6;
$midashi-color: #44605f;
$green: #7d987d;
$orange: #deb465;
$thin-orange: #ecd3a4;
$text-green: #8aa087;
$grey: #D0CEC6;
$footer-grey: #E5E4E2;
$gold: #B49E55;
$red: #af755b;
$bg-color: #F0EDE7;
$bg-wedding: #88857D;
$bg-grey: #F5F5F8;
$bg-grey2: #F2F0EC;
$white: #FFFFFF;
