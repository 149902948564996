@use "../global" as g;

.p-subsidy {


  .result-sec {
    background: #000;
    padding: 14rem 0 12rem;
    color: #fff;

    .inner {
      .container {
        display: flex;
        justify-content: center;

        @include g.mq-down(md) {
          display: block;
        }

        .result {
          margin: 0 4rem;

          @include g.mq-down(md) {
            text-align: center;

            @include g.mq-down(md) {
              &+.result {
                margin-top: 4rem;
              }
            }
          }

          .midashi {
            font-size: 3rem;
            line-height: 1.4;
          }

          .number {
            font-size: 12rem;
            font-weight: 700;
            line-height: 1.4;
            letter-spacing: 0.5rem;
            position: relative;

            @include g.mq-down(md) {
              letter-spacing: 0.7rem;
              width: fit-content;
              margin: 0 auto;
            }

            .unit {
              font-size: 6rem;
            }

            &::after {
              content: "";
              width: 100%;
              height: 2rem;
              position: absolute;
              bottom: 3rem;
              left: 0;
              z-index: -1;
              background: #E60013;
            }
          }
        }
      }

      small {
        display: block;
        text-align: center;
        font-size: 1.8rem;
        margin-top: 2rem;

        @include g.mq-down(md) {
          font-size: 2.6rem;
        }
      }
    }
  }

  .schedule-sec {
    background-color: #000;
    position: relative;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.7);
      mix-blend-mode: multiply;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      color: #fff;

      .midashi {
        @include g.mq-down(md) {
          margin-bottom: 4rem;
        }

        .en {
          font-weight: 700;
          display: inline-block;
          font-size: 5rem;
          letter-spacing: 0.3rem;
          margin-right: 3rem;

          @include g.mq-down(md) {
            font-size: 6.3rem;
          }
        }

        .ja {
          display: inline-block;
          font-size: 2rem;

          @include g.mq-down(md) {
            display: block;
            font-size: 3rem;
          }
        }
      }

      .flow {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: calc(100% + 8rem);
        margin-left: -4rem;

        @include g.mq-down(md) {
          width: calc(100% + 6rem);
          margin-left: -3rem;
        }

        .item {

          width: 20rem;
          height: 20rem;
          margin: 8rem 4rem 0;
          position: relative;

          @include g.mq-down(md) {
            width: 28.2rem;
            height: 28.2rem;
            margin: 8rem 3rem 0;
          }

          .number {
            font-size: 5.3rem;
            font-weight: 700;
            color: #808080;
            position: absolute;
            top: -3rem;
            left: 0;

            @include g.mq-down(md) {
              font-size: 7.5rem;
              top: -4rem;
            }
          }

          figure {
            width: 100%;
            height: 100%;

          }
        }
      }

      small {
        font-size: 2rem;
        display: block;
        text-align: center;
        margin-top: 6rem;

        @include g.mq-down(md) {
          margin-top: 8rem;
          font-size: 3.2rem;
        }
      }
    }

    figure {
      width: 100%;
      height: 90.9rem;
      @include g.imgFit();

      @include g.mq-down(md) {
        height: 205.2rem;
      }
    }
  }

  .interview-sec {
    background: #F7F7F7;
    padding: 14rem 0;

    @include g.mq-down(md) {
      padding: 15rem 0;
    }

    .inner {
      .midashi {
        font-size: 4.2rem;
        letter-spacing: 0.3rem;
        font-weight: 700;
        margin-bottom: 8rem;

        @include g.mq-down(md) {
          font-size: 6.4rem;
          margin-bottom: 8rem;
        }
      }

      .container {
        display: flex;
        justify-content: space-between;
        @include g.image-link_zoom();

        @include g.mq-down(md) {
          display: block;
        }

        .photo {
          width: 56rem;

          @include g.mq-down(md) {
            width: 100%;
            margin-bottom: 3rem;
          }
        }

        .text {
          width: 48rem;

          @include g.mq-down(md) {
            width: 100%;
          }

          .nakamidashi {
            font-size: 3.4rem;
            margin-bottom: 2rem;
            font-weight: 700;

            @include g.mq-down(md) {
              font-size: 4.4rem;
            }
          }

          .company {
            margin-bottom: 0.5rem;

            @include g.mq-down(md) {
              margin-bottom: 1rem;
            }
          }

          .komidashi {
            color: #808080;
            margin-bottom: 1.5rem;
          }

          .desc {
            line-height: 1.8;
            text-align: justify;
          }

          .link-btn2 {
            margin-top: 4rem;
          }
        }
      }
    }
  }
}
