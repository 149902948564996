@use "../global" as g;

.p-marketing {
  .method-sec {
    background-color: #000;
    padding: 14rem 0;

    .inner {
      .container {
        display: flex;
        justify-content: space-between;
        color: #fff;
        background-image: url(../images/marketing/bg_method-01.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 21.3rem;

        @include g.mq-down(md) {
          display: block;
          background-size: 25.8rem;
          background-position: center calc(50% + 5rem);
        }

        .box {
          width: 48rem;

          @include g.mq-down(md) {
            width: 100%;

            &+.box {
              margin-top: 38rem;
            }
          }

          figure {
            width: 32rem;
            height: 32rem;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            margin: 0 auto 4rem;

            @include g.mq-down(md) {
              width: 40rem;
              height: 40rem;
            }

            &::after {
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              background-color: rgba(#000, 0.5);
              mix-blend-mode: multiply;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
            }

            figcaption {
              width: 100%;
              text-align: center;
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 2;
              transform: translate(-50%, -50%);
              font-size: 3rem;
              color: #fff;
              font-weight: 500;
              letter-spacing: 0.3rem;

              @include g.mq-down(md) {
                font-size: 3.75rem;
              }
            }

          }

          .desc {
            line-height: 2;
            text-align: justify;
          }
        }
      }
    }
  }

  .productions-sec {
    padding: 14rem 0;
    background: linear-gradient(180deg, transparent 0%, transparent 42.3rem, #000 42.3rem, #000 100%);

    @include g.mq-down(md) {
      background: linear-gradient(180deg, transparent 0%, transparent 196rem, #000 196rem, #000 100%);
    }

    .inner {
      .midashi {
        margin-bottom: 8rem;

        .en {
          display: inline-block;
          font-size: 5rem;
          font-weight: 700;
          letter-spacing: 0.3rem;
          margin-right: 2rem;

          @include g.mq-down(md) {
            display: block;
            font-size: 6.3rem;
            line-height: 1.3;
            margin-right: 0;
            margin-bottom: 2rem;
          }
        }

        .ja {
          display: inline-block;
          font-size: 2rem;
          font-weight: 700;

          @include g.mq-down(md) {
            display: block;
            font-size: 3rem;
          }
        }
      }

      .list {
        display: flex;
        justify-content: space-between;
        margin-bottom: 13rem;

        @include g.mq-down(md) {
          display: block;
        }

        .item {
          width: 24rem;
          position: relative;

          @include g.mq-down(md) {
            width: 50rem;
            margin: 0 auto;

            &+.item {
              margin-top: 10rem;
            }
          }

          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            background-color: rgba(#000, 0.5);
            mix-blend-mode: multiply;
          }

          figcaption {
            width: 100%;
            text-align: center;
            color: #fff;
            text-align: center;
            font-weight: 500;
            line-height: 1.4;
            letter-spacing: 0.3rem;
            position: absolute;
            bottom: 1.5rem;
            left: 0;
            z-index: 2;

            @include g.mq-down(md) {
              font-size: 3.4rem;
              bottom: 4.4rem;
              letter-spacing: 0.5rem;
            }
          }
        }
      }

      .catch {
        font-size: 3.4rem;
        font-weight: 700;
        text-align: center;
        line-height: 1.8;
        letter-spacing: 0.3rem;
        color: #fff;

        @include g.mq-down(md) {
          font-size: 4.4rem;
        }
      }
    }
  }
}
