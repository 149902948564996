@use "../global" as g;

.p-contact {
  .content-sec {
    padding: 14rem 0;

    .inner {
      width: 84rem;
      margin: 0 auto;

      @include g.mq-down(md) {
        width: 67.8rem;
      }
    }

    .midashi {
      font-size: 2.2rem;
      font-weight: 700;
      margin-bottom: 60px;
      text-align: center;

      @include g.mq-down(md) {
        font-size: 3.2rem;
        margin-bottom: 2rem;
      }
    }

    #container {
      #mailformpro {
        margin: 0;
      }

      dl {
        margin-bottom: 20px;


        dt {
          width: 100%;
          float: none;
          text-align: left;
          border: none;
          font-size: 1.8rem;
          font-weight: 700;
          margin-bottom: 1rem;

          @include g.mq-down(md) {
            font-size: 3.2rem;
          }
        }

        dd {
          width: 100%;
          padding: 0;
          border: none;
          font-size: 1.6rem;

          @include g.mq-down(md) {
            font-size: 3rem;
          }
        }
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      select {
        width: 100%;
        max-width: 100%;
        border: 0.1rem solid #000;
        background: #fff;
        padding: 1.5rem 2rem;
        font-size: 1.6rem;
        line-height: 1.6;

        @include g.mq-down(md) {
          font-size: 2.6rem;
        }
      }

      .textarea {
        width: 100% !important;
        max-width: 100%;
        background: #fff;
        border: 0.1rem solid #000;
        padding: 1.5rem 2rem;
        font-size: 2rem;
        line-height: 2;

        @include g.mq-down(md) {
          font-size: 2.6rem;
        }
      }

      .must {
        width: fit-content;
        margin-left: 2rem;
        display: inline-block;
        background: #808080;
        border-radius: 9999px;
        border: none;
        color: #fff;
        box-shadow: none;
        text-shadow: none;
        float: none;
        margin: 0;
        font-size: 1.4rem;
        padding: 0.1rem 2rem;
        font-weight: 400;
        text-align: center;
      }

      .mfp_err {
        font-size: 1.4rem;

        @include g.mq-down(md) {
          font-size: 2.2rem;
        }
      }

      .select-wrap {
        position: relative;
        width: 100%;

        &::after {
          content: "";
          position: absolute;
          right: 10px;
          top: 17px;
          width: 12px;
          height: 6px;
          border-right: 6px solid transparent;
          border-bottom: 12px solid #999;
          border-left: 6px solid transparent;
          transform: scaleY(0.6);

          pointer-events: none;

        }

        &::before {
          content: "";
          position: absolute;
          right: 10px;
          bottom: 17px;
          width: 12px;
          height: 6px;
          border-right: 6px solid transparent;
          border-bottom: 12px solid #999;
          border-left: 6px solid transparent;
          transform: scaleY(0.6) rotate(180deg);
          pointer-events: none;
        }
      }

      .radio-wrap {
        display: flex;
        gap: percentage($number: 20px / 1080px);

        @include g.mq-down(md) {
          display: block;
        }

        .box {
          width: 50%;

          @include g.mq-down(md) {
            width: 100%;
            margin-bottom: 20px;

            &:nth-last-of-type(1) {
              margin-bottom: 0;
            }
          }

          .komidashi {
            font-weight: 700;
            margin-bottom: 10px;
          }

          /* ラジオボタン */
          .radio-btns p {
            margin-bottom: 20px;
          }

          .radio-btns input[type=radio] {
            display: none;
          }

          .radio-btns label {
            display: block;
            position: relative;
            padding: 20px 40px;
            margin: 0;
            border-radius: 0;
            background: #CCC;
          }

          .radio-btns input[type=radio] {
            &~.label::before {
              position: absolute;
              content: '';
              display: block;
              width: 2rem;
              height: 2rem;
              border-radius: 50%;
              background: g.$white;
              border: 0.1rem solid g.$white;
              left: 1.5rem;
              top: 50%;
              transform: translateY(-50%);
            }

            &~.label::after {
              position: absolute;
              content: '';
              display: block;
              width: 1.4rem;
              height: 1.4rem;
              border-radius: 50%;
              background: #000;
              left: calc(1.5rem + 3px);
              top: 50%;
              transform: translateY(-50%);
              opacity: 0;
              transition: .1s;
            }
          }

          .radio-btns input[type=radio]:checked {
            &~.label::after {
              opacity: 1;
            }
          }
        }
      }

      .pp-check {
        background: #dedede;
        padding: 3rem 0;
        margin: 4rem 0 6rem;

        @include g.mq-down(md) {
          padding: 2rem 0;
          margin: 4rem 0 2rem;
        }

        dl {
          margin-bottom: 0;

          dt {
            text-align: center;
          }

          dd {
            text-align: center;

            label {
              margin-bottom: 2rem;
              font-weight: 700;
            }

            .mfp_err {
              text-align: center;
              margin-top: -1rem;
              margin-bottom: 0.5rem;
            }

            p {
              @include g.mq-down(md) {
                font-size: 2.6rem;
              }

              a {
                text-decoration: underline;
                display: inline-block;
                margin-right: 0.5em;

                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }

      .checkbox-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include g.mq-down(md) {
          display: block;
        }

        .item {
          width: 50%;
          font-size: 2rem;
          margin-bottom: 1rem;
        }
      }

      .visibility-hidden {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
        cursor: none !important;
      }

      .checkbox-text {
        display: inline-block;
        padding: 0.5rem 0 0.5rem 4.5rem;
        position: relative;
        font-size: 1.8rem;

        @include g.mq-down(md) {
          padding: 1rem 0 1rem 8rem;
          font-size: 3rem;
        }

        &::before {
          content: '';
          display: block;
          width: 3rem;
          height: 3rem;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          border: 1px solid #808080;
          background-color: #fff;

          @include g.mq-down(md) {
            width: 6rem;
            height: 6rem;
          }
        }

        &::after {
          content: "";
          display: block;
          width: 0.7rem;
          height: 1.6rem;
          position: absolute;
          top: calc(50% - 0.1rem);
          left: 0.4rem;
          transform: rotate(45deg) skewY(16deg) translateY(-50%);
          transform-origin: center right;
          border-right: 0.1rem solid #000;
          border-bottom: 0.1rem solid #000;
          opacity: 0;

          @include g.mq-down(md) {
            width: 1.7rem;
            height: 3.2rem;
            top: calc(50% - 0.3rem);
          }
        }
      }

      .mfp_checked {
        .checkbox-text::after {
          opacity: 1;
        }
      }
    }

    .mfp_buttons {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;

      @include g.mq-down(md) {
        display: block;
      }

      button {
        margin: 0 2rem;
        width: 22rem;

        @include g.mq-down(md) {
          width: 40rem;

          &+button {
            margin-top: 3rem;
          }
        }
      }

      button[type="submit"],
      #mfp_button_send {
        background: #808080;
        border: 0.1rem solid #808080;
        padding: 0.8rem 6rem;
        border-radius: 9999px;
        color: #fff;
        position: relative;
        letter-spacing: 0.3rem;
        text-shadow: none;
        box-shadow: none;

        @include g.mq-down(md) {
          padding: 2rem 6rem;
        }

        &::before {
          content: "";
          position: absolute;
          top: calc(50% - 0.4rem);
          right: 1.5rem;
          transform: translateY(-50%);
          width: 0.8rem;
          height: 0.8rem;
          border-top: 0.1rem solid #fff;
          border-right: 0.1rem solid #fff;
          transform: rotate(45deg);

          @include g.mq-down(md) {
            width: 1.2rem;
            height: 1.2rem;
            top: calc(50% - 0.6rem);
            right: 3rem;
          }
        }
      }

      #mfp_button_cancel {
        background: #fff;
        border: 0.1rem solid #808080;
        padding: 0.8rem 6rem;
        border-radius: 9999px;
        color: #808080;
        position: relative;
        letter-spacing: 0.3rem;
        text-shadow: none;
        box-shadow: none;

        @include g.mq-down(md) {
          padding: 2rem 6rem;
        }

        &::before {
          content: "";
          position: absolute;
          top: calc(50% - 0.4rem);
          left: 1.5rem;
          transform: translateY(-50%);
          width: 0.8rem;
          height: 0.8rem;
          border-top: 0.1rem solid #808080;
          border-right: 0.1rem solid #808080;
          transform: rotate(-135deg);

          @include g.mq-down(md) {
            width: 1.2rem;
            height: 1.2rem;
            top: calc(50% - 0.6rem);
            left: 3rem;
          }
        }
      }
    }

    //確認画面
    #mfp_phase_confirm {
      h4 {
        display: none;
      }

      #mfp_confirm_table {
        tr {
          th {
            width: 32rem;
            padding: 3rem 0;
            font-size: 2rem;
            line-height: 1.8;

            @include g.mq-down(md) {
              width: 100%;
              display: block;
              border-bottom: none;
              padding-bottom: 1rem;
              font-size: 3rem;
            }
          }

          td {
            padding: 3rem 0;
            font-size: 2rem;
            line-height: 1.8;

            @include g.mq-down(md) {
              width: 100%;
              display: block;
              border-top: none;
              padding-top: 0;
              font-size: 2.6rem;
            }
          }
        }
      }


    }

    //完了画面
    #mfp_thanks {
      display: none;
    }

    .thanks-message {
      font-size: 2.8rem;
      text-align: center;
      font-weight: 700;
      margin-bottom: 2rem;

      @include g.mq-down(md) {
        font-size: 3.8rem;
        margin-bottom: 4rem;
      }
    }

    .thanks-message2 {
      font-size: 2.2rem;
      text-align: center;
      font-weight: 700;

      @include g.mq-down(md) {
        font-size: 3.2rem;
      }
    }


  }
}
